import React from 'react';
import { Helmet } from 'react-helmet';
import Snackbar from '@components/Snackbar';

const Components = () => (
  <div style={{ width: '500px' }}>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      ></link>
    </Helmet>
    <Snackbar message="Teste" icon="info" />
    <Snackbar type="info-white">Teste</Snackbar>
    <Snackbar message="Teste" type="success" />
    <Snackbar message="Teste" type="error" />
  </div>
);

export default Components;
